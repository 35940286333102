import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


const MXMRPpage = () => (
    <Layout>
        <SEO title="MxMRP" keywords={[`Nesting`, `Production Environment`, `react`]} />
        <div className="BlueGradient">
            <div className="--headerClear"></div>
            <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation ">
                <h2><span>MxMRP</span> is real time monitoring</h2>
                <p>NEED TEXT Tap into real-time information on your shop floor and enjoy the benefits of increased production. </p>
            </section>
         </div>
         <section className="--centerMargin --maxwidthL">
            <article id="--productOverview">
                <h5>Product Overview</h5>
                <p className="DarkP">Eliminate time-consuming manual shop floor processes with an automatic on-the-fly system that puts accurate production data at your fingertips. MxMRP monitors and tracks every job in production and properly allocates cut times, part counts and inventory usage.  MxMRP can act as a stand-alone system or integrate into your existing shop floor data collection system. Because it’s customizable, you don’t have to match our data format - we conform to yours.</p>
            </article>
        </section>
        <section className="--centerMargin --maxwidthL --grid6040 --edgePadding --sectionPace --alignCenter">
        <article>
            <h2 className="DarkH">Handle unplanned production changes with ease.</h2>
            <p className="DarkP">Make production changes or insert hot jobs and quickly know the results. Real-time monitoring and the ability to regulate output gives management more control over work flow. </p>
        </article>
        <div id="mrpChanges" className="--backgroundContain"></div>
    </section>
    <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid5050">
      <article className="--maxwidthS">
        <div className="--paragraphImg" id="paperShuffleImg"></div>
        <h5>Eliminate unproductive paper shuffling.</h5>
        <p className="DarkP">Knowledge is power. Track part counts, cycle times, material usage and heat tag/lot numbers with accurate allocation.  Material and scrap adjustments are immediate in MRP. </p>
      </article>
      <article className="--maxwidthS">
        <div className="--paragraphImg" id="finishedJobsImg"></div>
        <h5>All of your finished jobs in one place</h5>
        <p className="DarkP">No more error-prone and time consuming manual reporting. Get an accurate and fast look at all of you completed jobs</p>
      </article>
    </section>
    <section className="--sectionPace --centerTxt">
      <h2 className="DarkH">Expand MxStation with additional modules</h2>
      <p className="DarkP">Customize and control your suite of Mx products based on your shop needs. </p>
      <div className="--headerClear"></div>
      <div className="MXproducts" id="MxProductPage">
        <Link to="/mx"><span><h4>MxManager</h4></span></Link>
          <Link to="/mxmrp"> 
            <div id="ProductChip">
              <h4>MxMRP</h4>
              <hr></hr>
              <p>Real-Time Monitoring</p>
            </div>
          </Link>
          <Link to="/mxpallet"> 
            <div id="ProductChip">
              <h4>MxPallet</h4>
              <hr></hr>
              <p>Expedite finished pallets</p>
            </div>
          </Link>
          <Link to="/mxticket"> 
            <div id="ProductChip">
              <h4>MxTicket</h4>
              <hr></hr>
              <p>Smarter Labels & Tickets</p>
            </div>
          </Link>
          <Link to="/mxstation"> 
            <div id="ProductChip">
              <h4>MxStation</h4>
              <hr></hr>
              <p>Process Queue Control</p>
            </div>
          </Link>
          <Link to="/mxreport"> 
            <div id="ProductChip">
              <h4>MxReport</h4>
              <hr></hr>
              <p>Pinpoint Documentation</p>
            </div>
          </Link>
        </div>
      </section>
      <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">
        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
    </Layout>
)

export default MXMRPpage